<div class="container">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="form-inputs">
      <!-- Invoice Number Input -->
      <div class="form-group">
        <label for="invoiceNumber">Invoice Number</label>
        <input id="invoiceNumber" formControlName="invoiceNumber" type="text" />
      </div>

      <!-- Customer Name Input -->
      <div class="form-group">
        <label for="customerName">Customer Name</label>
        <input id="customerName" formControlName="customerName" type="text" />
      </div>

      <!-- Location Name Input -->
      <div class="form-group">
        <label for="locationName">Location Name</label>
        <input id="locationName" formControlName="locationName" type="text" />
      </div>

      <!-- Vendor Input -->
      <div class="form-group">
        <label for="vendor">Vendor</label>
        <input id="vendor" formControlName="vendor" type="text" />
      </div>

      <!-- Pricing Agreement Input -->
      <div class="form-group">
        <label for="pricingAgreementName">Pricing Agreement Name</label>
        <input
          id="pricingAgreementName"
          formControlName="pricingAgreementName"
          type="text" />
      </div>

      <div class="form-inputs">
        <!-- Location State Dropdown -->
        <div class="form-group">
          <label for="locationState">Location State</label>
          <select
            id="locationState"
            formControlName="locationState"
            class="form-control">
            <option value="" disabled>Select State</option>
            <option *ngFor="let state of states" [value]="state.StateID">
              {{ state.StateName }}
            </option>
          </select>
        </div>

        <!-- Audit Status Dropdown -->
        <div class="form-group">
          <label for="auditStatus">Audit Status</label>
          <select
            id="auditStatus"
            formControlName="auditStatus"
            class="form-control">
            <option value="" disabled>Select Status</option>
            <option
              *ngFor="let status of auditStatuses"
              [value]="status.AuditStatusID">
              {{ status.AuditStatusName }}
            </option>
          </select>
        </div>

        <!-- Product Category Dropdown -->
        <div class="form-group">
          <label for="productCategory">Product Category</label>
          <select
            id="productCategory"
            formControlName="productCategory"
            class="form-control">
            <option value="" disabled>Select Category</option>
            <option
              *ngFor="let category of productCategories"
              [value]="category.ProductCategoryID">
              {{ category.ProductCategoryName }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Submit Button -->
    <button
      type="submit"
      style="margin-top: 10px"
      [disabled]="searchForm.invalid">
      Search
    </button>
  </form>

  <div style="margin-top: 10px">
    <!-- Radio Buttons for Column Group Selection -->
    <label>
      <input
        type="radio"
        name="columnGroup"
        value="Tax"
        [(ngModel)]="selectedGroup"
        (change)="updateColumns()" />
      Tax Auditing View
    </label>
    <label>
      <input
        type="radio"
        name="columnGroup"
        value="Price"
        [(ngModel)]="selectedGroup"
        (change)="updateColumns()" />
      Price Auditing View
    </label>
  </div>

  <!-- ag-Grid Component for Displaying Data -->
  <ag-grid-angular
    class="ag-theme-quartz"
    [autoSizeStrategy]="autoSizeStrategy"
    [rowSelection]="rowSelection"
    [rowData]="rowData"
    [columnDefs]="colDefs"
    [stopEditingWhenCellsLoseFocus]="true"
    style="height: 50vh; margin-top: 10px"
    (cellValueChanged)="onCellValueChanged($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (gridReady)="onGridReady($event)"
    #grid />

  <br />

  <!-- Save Button -->
  <button [disabled]="updatedRowIds.length === 0" (click)="saveClicked()">
    Save
  </button>

  <button style="margin-left: 15px;" [disabled]="!canBulkSave" (click)="confirmBulkSaveClicked()">
    Bulk Save
  </button>
  <br />
</div>
