import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import {
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridOptions,
  ISelectCellEditorParams,
  RowSelectionOptions,
  SelectionChangedEvent,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  ValueFormatterParams,
} from 'ag-grid-community'; // Column Definition Type Interface
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { GridService } from '../grid-service/grid.service';
import { CommonModule } from '@angular/common';
import { Customer } from '../interfaces/customer';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CustomerLocation } from '../interfaces/customer-location';
import { BulkMobileTransactionDto } from '../interfaces/dto/bulk-mobile-transaction.dto';
import { PricingAgreementFilterDto } from '../interfaces/dto/pricing-agreement-filter.dto';
import { ProductCategoryDto } from '../interfaces/dto/product-category.dto';
import { NetworkDto } from '../interfaces/dto/network.dto';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StateDto } from '../interfaces/dto/state.dto';
import { HttpParams } from '@angular/common/http';
import { AuditStatusDto } from '../interfaces/dto/audit-status.dto';
import { FuelingTypeDto } from '../interfaces/dto/fueling-type.dto';
import { BulkUpdateDto } from '../interfaces/dto/bulk-save.dto';

@Component({
  selector: 'app-grid',
  standalone: true,
  imports: [AgGridAngular, FormsModule, CommonModule, ReactiveFormsModule],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.less',
})
export class GridComponent implements OnInit {
  @ViewChild('grid') grid!: AgGridAngular;
  private _snackBar = inject(MatSnackBar);
  private gridApi!: GridApi;

  selectedGroup: string = 'Tax';
  customers: Customer[] = [];
  customerLocations: CustomerLocation[] = [];
  pricingAgreementFilterDtos: PricingAgreementFilterDto[] = [];
  networks: NetworkDto[] = [];
  productCategories: ProductCategoryDto[] = [];
  auditStatuses: AuditStatusDto[] = [];
  fuelingTypes: FuelingTypeDto[] = [];

  states: StateDto[] = [];

  rowData: BulkMobileTransactionDto[] = [];
  colDefs: ColDef[] = [];
  updatedRowIds: number[] = [];

  customerMap = new Map<string, Customer>();
  customerLocationMap = new Map<string, CustomerLocation>();
  pricingAgreementFilterMap = new Map<string, PricingAgreementFilterDto>();
  networkMap = new Map<string, NetworkDto>();
  productCategoriesMap = new Map<string, ProductCategoryDto>();
  taxAuditStatusesMap = new Map<string, AuditStatusDto>();
  pricingAuditStatusesMap = new Map<string, AuditStatusDto>();
  stateMap = new Map<string, StateDto>();

  bulkSaveColumnTracker: string[] = [];
  public gridOptions: GridOptions;

  searchForm!: FormGroup;
  taxViewColumns = [
    'TaxAuditStatusDto.TaxAuditStatusName',
    'TaxAuditNotes',
    'InvoiceDetailDto.Customer.CustomerName',
    'InvoiceDetailDto.Network.NetworkName', // Vendor
    'InvoiceDetailDto.CustomerLocation.LocationName',
    'InvoiceDetailDto.CustomerLocation.State.StateName',
    'InvoiceDetailDto.ProductCategory.ProductCategoryName',
    'InvoiceDetailDto.Gallons',
    'InvoiceDetailDto.TotalTax',
    'DealPerGallonTaxes',
    'DealSalesTax',
    'OverUnderTaxes',
    'TotalInvoiceOverUnderTaxes',
    'PriceAgreementDto.OPISRackCity',
    'PriceAgreementDto.OPISProductName',
    'InvoiceDetailDto.DINUrl',
    'InvoiceDetailDto.InvoiceNumber',
    'InvoiceDetailDto.TransactionDate',
    'InvoiceDetailDto.PPG',
    'PriceAgreementDto.TaxesInPPG',
    'InvoiceDetailDto.Freight',
    'InvoiceDetailDto.Surcharge',
    'InvoiceDetailDto.PumpFees',
    'InvoiceDetailDto.Additives',
    'InvoiceDetailDto.OtherFees1',
    'InvoiceDetailDto.OtherFees2',
    'InvoiceDetailDto.OtherFees3',
    'InvoiceDetailDto.TotalInvoiceAmount',
    'Notes',
  ];

  pricingViewColumns = [
    'PriceAgreementDto.PricingAgreementName',
    'InvoiceDetailDto.DINUrl',
    'InvoiceDetailDto.InvoiceNumber',
    'InvoiceDetailDto.TransactionDate',
    'InvoiceDetailDto.Gallons',
    'InvoiceDetailDto.PPG',
    'OPISPrice',
    'OverUnderAllInMargin',
    'OverUnderOPIS',
    'OverUnderFreightSurcharge',
    'OverUnderPumpFee',
    'OverUnderAdditive',
    'PricingAuditStatusDto.PricingAuditStatusName',
    'PricingAuditNotes',
    'AllInMargin',
    'PriceAgreementDto.DealAllInMargin',
    'PPGvsOPIS',
    'PriceAgreementDto.DealOPISMargin',
    'FreightSurcharge',
    'PriceAgreementDto.DealFreightSurcharge',
    'PriceAgreementDto.PumpFeeDeal',
    'PriceAgreementDto.DealAdditive',
    'InvoiceDetailDto.Customer.CustomerName',
    'InvoiceDetailDto.CustomerLocation.LocationName',
    'InvoiceDetailDto.Network.NetworkName', // Vendor
    'InvoiceDetailDto.ProductCategory.ProductCategoryName',
    'InvoiceDetailDto.Freight',
    'InvoiceDetailDto.Surcharge',
    'InvoiceDetailDto.PumpFees',
    'InvoiceDetailDto.Additives',
    'InvoiceDetailDto.TotalTax',
    'InvoiceDetailDto.TotalInvoiceAmount',
    'PriceAgreementDto.OPISProductName',
    'PriceAgreementDto.OPISRackCity',
    'PriceAgreementDto.OPISDateModifier',
    'PriceAgreementDto.AdditiveInPPG',
    'PriceAgreementDto.PumpFeesInAllInMargin',
    // Doc Parse Received Date 🤷‍♂️
  ];

  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
    type: "fitCellContents",
    skipHeader: true,
  };

  public rowSelection: RowSelectionOptions = {
    mode: 'multiRow'
  }

  bulkSaveTrackedColumns = [
    'TaxAuditNotes', 
    'PricingAuditNotes',
    'TaxAuditStatusDto.TaxAuditStatusName', 
    'PriceAgreementDto.PricingAgreementName', 
    'PricingAuditStatusDto.PricingAuditStatusName',
  ];
  
  bulkSaveEditedColumns : Map<string, string> = new Map();
  canBulkSave: boolean = false;

  constructor(private readonly gridService: GridService) {
    this.gridOptions = {
      onCellValueChanged: (event) => this.onCellValueChanged(event),
    };
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      invoiceNumber: new FormControl(''),
      customerName: new FormControl(''),
      locationName: new FormControl(''),
      vendor: new FormControl(''),
      pricingAgreementName: new FormControl(''),
      locationState: new FormControl(''),
      auditStatus: new FormControl(''),
      productCategory: new FormControl(''),
    });

    this.fetchAndProcessCustomerData();
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
  }

  private fetchCustomerDate(){
    this.gridService.getBulkMobileTransactions(this.getSearchParams()).subscribe({
      next: bulkMobileTransactions => {
        this.assignRowData(bulkMobileTransactions);
      },
    });
  }

  private fetchAndProcessCustomerData() {
    forkJoin([
      this.gridService.getCustomers(),
      this.gridService.getCustomerLocations(),
      this.gridService.getPricingAgreementFilterDtos(),
      this.gridService.getNetworkMaster(),
      this.gridService.getProductCategory(),
      this.gridService.getAuditStatuses(),
      this.gridService.getFuelingTypes(),
      this.gridService.getStates(),
      this.gridService.getBulkMobileTransactions(this.getSearchParams()),
    ]).subscribe(
      ([
        customers,
        customerLocations,
        pricingAgreementFilterDtos,
        networks,
        productCategories,
        auditStatuses,
        fuelingTypes,
        states,
        bulkMobileTransactions,
      ]) => {
        this.customers = [...customers];
        this.customerLocations = [...customerLocations];
        this.pricingAgreementFilterDtos = [...pricingAgreementFilterDtos];
        this.networks = [...networks];
        this.productCategories = [...productCategories];
        this.auditStatuses = [...auditStatuses];
        this.fuelingTypes = [...fuelingTypes];
        this.states = [...states];

        customers.forEach(customer =>
          this.customerMap.set(customer.Name, customer)
        );
        customerLocations.forEach(location =>
          this.customerLocationMap.set(location.Name, location)
        );
        pricingAgreementFilterDtos.forEach(filter =>
          this.pricingAgreementFilterMap.set(
            filter.PricingAgreementName,
            filter
          )
        );
        networks.forEach(network =>
          this.networkMap.set(network.NetworkName, network)
        );
        productCategories.forEach(category =>
          this.productCategoriesMap.set(category.ProductCategoryName, category)
        );

        auditStatuses.forEach(status =>{
          this.taxAuditStatusesMap.set(status.AuditStatusName, status)
          this.pricingAuditStatusesMap.set(status.AuditStatusName, status)
        });

        states.forEach(state => this.stateMap.set(state.StateName, state));
        this.assignRowData(bulkMobileTransactions);
        this.colDefs = this.initializeColDefs();
      }
    );
  }

  private initializeColDefs(): ColDef[] {
    let initialWidth = 150;
    let colDefs: ColDef[] = [
      { field: 'TransactionId' },
      {
        headerName: 'DIN Url',
        field: 'InvoiceDetailDto.DINUrl',
        cellRenderer: (params: ValueFormatterParams) =>
          `<a href="${params.value}" target="_blank" rel="noopener">${params.value}</a>`,
      },
      {
        headerName: 'Tax Audit Status',
        field: 'TaxAuditStatusDto.TaxAuditStatusName',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.auditStatuses.map(x => x.AuditStatusName),
        } as ISelectCellEditorParams,
        editable: true,
      },
      {
        headerName: 'Audit Status',
        field: 'PricingAuditStatusDto.PricingAuditStatusName',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.auditStatuses.map(x => x.AuditStatusName),
        } as ISelectCellEditorParams,
        editable: true,
      },
      {
        headerName: 'Tax Audit Notes',
        field: 'TaxAuditNotes',
        editable: true,
      },
      {
        headerName: 'Audit Notes',
        field: 'PricingAuditNotes',
        editable: true,
      },
      {
        headerName: 'Invoice Received Date',
        field: 'InvoiceDetailDto.InvoiceReceivedDate',
        editable: true,
        valueFormatter: (params: ValueFormatterParams<any, Date>) => {
          if (!params.value) {
            return '';
          }
          return new Intl.DateTimeFormat('en-US').format(params.value);
        },
        cellEditor: 'agDateCellEditor',
      },

      {
        headerName: 'Customer Name',
        field: 'InvoiceDetailDto.Customer.CustomerName',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.customers.map(x => x.Name),
        } as ISelectCellEditorParams,
        editable: true,
      },
      {
        headerName: 'Customer Location',
        field: 'InvoiceDetailDto.CustomerLocation.LocationName',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.customerLocations.map(x => x.Name),
        } as ISelectCellEditorParams,
        editable: true,
      },
      {
        headerName: 'Customer State',
        field: 'InvoiceDetailDto.CustomerLocation.State.StateName',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.states.map(x => x.StateName),
        } as ISelectCellEditorParams,
        editable: true,
      },
      {
        headerName: 'Vendor',
        field: 'InvoiceDetailDto.Network.NetworkName',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.networks.map(x => x.NetworkName),
        } as ISelectCellEditorParams,
        editable: true,
      },
      {
        headerName: 'Product Category',
        field: 'InvoiceDetailDto.ProductCategory.ProductCategoryName',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.productCategories.map(x => x.ProductCategoryName),
        } as ISelectCellEditorParams,
        editable: true,
      },
      {
        headerName: 'Invoice',
        field: 'InvoiceDetailDto.InvoiceNumber',
        editable: true,
      },
      {
        headerName: 'Invoice Date',
        field: 'InvoiceDetailDto.InvoiceDate',
        editable: true,
        valueFormatter: (params: ValueFormatterParams<any, Date>) => {
          if (!params.value) {
            return '';
          }
          return new Intl.DateTimeFormat('en-US').format(params.value);
        },
        cellEditor: 'agDateCellEditor',
      },
      {
        headerName: 'Transaction Date',
        field: 'InvoiceDetailDto.TransactionDate',
        editable: true,
        valueFormatter: (params: ValueFormatterParams<any, Date>) => {
          if (!params.value) {
            return '';
          }
          return new Intl.DateTimeFormat('en-US').format(params.value);
        },
        cellEditor: 'agDateCellEditor',
        minWidth: 130
      },
      {
        headerName: 'Gallons',
        field: 'InvoiceDetailDto.Gallons',
        editable: true,
        valueSetter: params => {
          if (params.newValue <= 0) {
            this.openSnackBar('Gallons should be greater than 0');
            return false;
          }
          params.data.InvoiceDetailDto.Gallons = params.newValue;
          return true;
        },
      },
      {
        headerName: 'PPG',
        field: 'InvoiceDetailDto.PPG',
        editable: true,
        valueFormatter: params => this.currencyFormatter(params, 4),
      },
      {
        headerName: 'Freight',
        field: 'InvoiceDetailDto.Freight',
        editable: true,
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Surcharge',
        field: 'InvoiceDetailDto.Surcharge',
        editable: true,
        valueFormatter: params => this.currencyFormatter(params),
        minWidth: 110
      },
      {
        headerName: 'Pump Fees',
        field: 'InvoiceDetailDto.PumpFees',
        editable: true,
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Additives',
        field: 'InvoiceDetailDto.Additives',
        editable: true,
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Total Taxes',
        field: 'InvoiceDetailDto.TotalTax',
        editable: true,
        valueFormatter: params => this.currencyFormatter(params, 4),
      },

      {
        headerName: 'Other Fees 1',
        field: 'InvoiceDetailDto.OtherFees1',
        editable: true,
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Other Fees 2',
        field: 'InvoiceDetailDto.OtherFees2',
        editable: true,
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Other Fees 3',
        field: 'InvoiceDetailDto.OtherFees3',
        editable: true,
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Number of Transactions',
        field: 'InvoiceDetailDto.NumberOfTransactions',
        editable: true,
      },
      {
        headerName: 'Federal Tax',
        field: 'InvoiceDetailDto.FederalTax',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'State Tax',
        field: 'InvoiceDetailDto.StateTax',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Other Tax',
        field: 'InvoiceDetailDto.OtherTax',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Sales Tax',
        field: 'InvoiceDetailDto.SalesTaxPct',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Total Invoice Amount',
        field: 'InvoiceDetailDto.TotalInvoiceAmount',
        valueFormatter: params => this.currencyFormatter(params),
      },


      {
        headerName: 'Pricing Agreement',
        field: 'PriceAgreementDto.PricingAgreementName',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.pricingAgreementFilterDtos.map(
            x => x.PricingAgreementName
          ),
        } as ISelectCellEditorParams,
        editable: true,
      },

      { headerName: 'Gross/Net', field: 'PriceAgreementDto.GrossNet' },
      {
        headerName: 'OPIS Price',
        field: 'OPISPrice',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'PPG vs OPIS',
        field: 'PPGvsOPIS',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Deal OPIS Margin',
        field: 'PriceAgreementDto.DealOPISMargin',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'OPIS Variance',
        field: 'OverUnderOPIS',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Freight + Surcharge',
        field: 'FreightSurcharge',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Deal Freight + Surcharge',
        field: 'PriceAgreementDto.DealFreightSurcharge',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Freight + Surcharge Variance',
        field: 'OverUnderFreightSurcharge',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Pump Fee Deal',
        field: 'PriceAgreementDto.PumpFeeDeal',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Pump Fee Variance',
        field: 'OverUnderPumpFee',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'All In Margin',
        field: 'AllInMargin',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Deal All In Margin',
        field: 'PriceAgreementDto.DealAllInMargin',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'All In Variance',
        field: 'OverUnderAllInMargin',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Deal Additive',
        field: 'PriceAgreementDto.DealAdditive',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Additive Variance',
        field: 'OverUnderAdditive',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Deal Per Gallon Taxes',
        field: 'DealPerGallonTaxes',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Deal Sales Tax',
        field: 'DealSalesTax',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Tax Variance',
        field: 'OverUnderTaxes',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: "Total Invoice Tax Variance",
        field: 'TotalInvoiceOverUnderTaxes',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Cost of Fuel',
        field: 'CostOfFuel',
        editable: true,
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Cost Over',
        field: 'CostOver',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'All In Price',
        field: 'AllInPrice',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Baseline',
        field: 'PriceAgreementDto.Baseline',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'All In Margin Over (Under) Baseline',
        field: 'AllInMarginOverUnderBaseline',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Savings',
        field: 'Savings',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Total Per Gallon Taxes',
        field: 'TotalPerGallonTaxes',
        valueFormatter: params => this.currencyFormatter(params),
      },      {
        headerName: 'Adjusted Sales Tax',
        field: 'AdjustedSalesTax',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'OPIS Product',
        field: 'PriceAgreementDto.OPISProductName',
      },
      {
        headerName: 'OPIS City',
        field: 'PriceAgreementDto.OPISRackCity',
      },
      {
        // Datatype bool
        headerName: 'OPIS Date Modifier',
        field: 'PriceAgreementDto.OPISDateModifier',
        cellDataType:'text',
      },
      {
        // Datatype bool
        headerName: 'Additive in PPG',
        field: 'PriceAgreementDto.AdditiveInPPG',
        cellDataType:'text',
      },
      {
        // Datatype bool
        headerName: 'Pump Fees in All In Margin',
        field: 'PriceAgreementDto.PumpFeesInAllInMargin',
        cellDataType:'text',
      },
      {
        headerName: 'Taxes In PPG',
        field: 'PriceAgreementDto.TaxesInPPG',
        valueFormatter: params => this.currencyFormatter(params),
      },
      {
        headerName: 'Fueling Type',
        field: 'PriceAgreementDto.PrimaryFuelingType.FuelingTypeName',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.fuelingTypes.map(x => x.FuelingTypeName),
        } as ISelectCellEditorParams,
        editable: true,
      },
      { headerName: 'Notes', field: 'Notes', editable: true },
    ];
    

    if (this.selectedGroup === 'Tax') {
      colDefs = this.taxViewColumns.map(x => colDefs.find(y => y.field === x)!);
    } else if (this.selectedGroup === 'Price') {
      colDefs = this.pricingViewColumns.map(
        x => colDefs.find(y => y.field === x)!
      );
    }

    colDefs.forEach((col) => {
      col.wrapHeaderText = true;
      if(col.minWidth === undefined){
        col.minWidth = 100;
      }
      col.autoHeaderHeight = true;
    });

    return colDefs;
  }

  onCellValueChanged(event: CellValueChangedEvent) {
    if (
      event.rowIndex !== undefined &&
      event.rowIndex !== null &&
      !this.updatedRowIds.includes(event.rowIndex)
    ) {
      this.updatedRowIds.push(event.rowIndex);
    }

    if(this.updatedRowIds.length > 1){
      this.bulkSaveEditedColumns.clear();
      return;
    }

    const fieldUpdate = event.colDef.field;
    if (fieldUpdate !== null && fieldUpdate !== undefined && this.isEligibleForBulkSave(fieldUpdate)) {
      this.updateBulkSaveColumns(fieldUpdate, event);
    }
    this.updateCanBulkSave();
  }

  private isEligibleForBulkSave(fieldUpdate: string): boolean {
    return this.updatedRowIds.length === 1 && this.bulkSaveTrackedColumns.includes(fieldUpdate);
  }

  private updateBulkSaveColumns(fieldUpdate: string, event: CellValueChangedEvent) {
    switch (fieldUpdate) {
      case 'TaxAuditNotes':
      case 'PricingAuditNotes':
        this.bulkSaveEditedColumns.set(fieldUpdate, event.newValue);
        break;

      case 'TaxAuditStatusDto.TaxAuditStatusName': {
        let taxAuditStatusId = this.taxAuditStatusesMap.get(event.newValue)?.AuditStatusID;
        let taxAuditStatusIdString = taxAuditStatusId ? taxAuditStatusId.toString() : '';
        this.bulkSaveEditedColumns.set('TaxAuditStatusID', taxAuditStatusIdString);
        break;
      }

      case 'PriceAgreementDto.PricingAgreementName': {
        let pricingAgreementId = this.pricingAgreementFilterMap.get(event.newValue)?.PricingAgreementID;
        let pricingAgreementIdString = pricingAgreementId ? pricingAgreementId.toString() : '';
        this.bulkSaveEditedColumns.set('PricingAgreementID', pricingAgreementIdString);
        break;
      }

      case 'PricingAuditStatusDto.PricingAuditStatusName': {
        let pricingAuditStatusId = this.pricingAuditStatusesMap.get(event.newValue)?.AuditStatusID;
        let pricingAuditStatusIdString = pricingAuditStatusId ? pricingAuditStatusId.toString() : '';
        this.bulkSaveEditedColumns.set('PricingAuditStatusID', pricingAuditStatusIdString);
        break;
      }

      default:
        break;
    }
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    this.updateCanBulkSave();
  }

  saveClicked() {
    this.grid.api.stopEditing();
    const updatedRows = this.updatedRowIds.map(rowId => this.rowData[rowId]);
    updatedRows.forEach(row => this.updateRowData(row));
    this.gridService.updateBulkMobileTransactions(updatedRows).subscribe({
      next: () => {
        this.openSnackBar('Data saved successfully');
        this.resetGrid();
        this.fetchCustomerDate();
      },
      error: () => {
        this.openSnackBar('Failed to save data');
      },
    });
  }

  
  updateCanBulkSave(): void {
    this.canBulkSave = this.bulkSaveEditedColumns.size > 0 && this.getNumberOfSelectedRows() > 1;
  }

  confirmBulkSaveClicked(): void {
    const isConfirmed = window.confirm('Are you sure you want to save the changes for all selected rows?');
    if(isConfirmed){
      this.bulkSaveClicked();
    }
  }

  bulkSaveClicked(): void {
    this.gridApi.stopEditing();
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedRows: BulkMobileTransactionDto[] = selectedNodes.map(node => node.data);
    const updatedRows: BulkUpdateDto = {
      TransactionIds: selectedRows.map(row => row.TransactionId),
      EditedColumnMap: Object.fromEntries(this.bulkSaveEditedColumns),
    };
    
    this.gridService.bulkUpdateBulkMobileTransactions(updatedRows).subscribe({
      next: () => {
        this.openSnackBar('Data saved successfully');
        this.resetGrid();
        this.fetchCustomerDate();
      },
      error: () => {
        this.openSnackBar('Failed to save data');
      },
    });
  }

  private resetGrid() {
    this.gridApi.deselectAll();
    this.updatedRowIds = [];
    this.bulkSaveEditedColumns.clear();
  }

  private getNumberOfSelectedRows(): number {
    return this.gridApi ? this.gridApi.getSelectedRows().length : 0;
  }

  private updateRowData(row: BulkMobileTransactionDto): void {
    this.updateCustomerData(row);
    this.updateCustomerLocation(row);
    this.updateNetworkData(row);
    this.updateProductCategoryData(row);
    this.updatePricingAgreementData(row);
    this.updateTaxAuditStatusData(row);
    this.updatePricingAuditStatusData(row);
    this.updateStateData(row);
  }

  private updateCustomerData(row: BulkMobileTransactionDto): void {
    const customer = this.customerMap.get(
      row.InvoiceDetailDto.Customer?.CustomerName
    );
    if (customer) {
      row.InvoiceDetailDto.Customer.CustomerID = customer.CustomerID;
    }
  }

  private updateCustomerLocation(row: BulkMobileTransactionDto): void {
    const location = this.customerLocationMap.get(
      row.InvoiceDetailDto.CustomerLocation?.LocationName
    );
    if (location) {
      row.InvoiceDetailDto.CustomerLocation.CustomerLocationID =
        location.CustomerLocationID;
    }
  }

  private updateNetworkData(row: BulkMobileTransactionDto): void {
    const network = this.networkMap.get(
      row.InvoiceDetailDto.Network?.NetworkName
    );
    if (network) {
      row.InvoiceDetailDto.Network.NetworkID = network.NetworkID;
    }
  }

  private updateProductCategoryData(row: BulkMobileTransactionDto): void {
    const category = this.productCategoriesMap.get(
      row.InvoiceDetailDto.ProductCategory?.ProductCategoryName
    );
    if (category) {
      row.InvoiceDetailDto.ProductCategory.ProductCategoryID =
        category.ProductCategoryID;
    }
  }

  private updatePricingAgreementData(row: BulkMobileTransactionDto): void {
    const pricingAgreement = this.pricingAgreementFilterMap.get(
      row.PriceAgreementDto.PricingAgreementName
    );
    if (pricingAgreement) {
      row.PricingAgreementID = pricingAgreement.PricingAgreementID;
    }
  }

  private updateTaxAuditStatusData(row: BulkMobileTransactionDto): void {
    const taxAuditStatus = this.taxAuditStatusesMap.get(
      row.TaxAuditStatusDto.TaxAuditStatusName
    );
    if (taxAuditStatus) {
      row.TaxAuditStatusDto.TaxAuditStatusID = taxAuditStatus.AuditStatusID;
    }
  }

  private updatePricingAuditStatusData(row: BulkMobileTransactionDto): void {
    const pricingAuditStatus = this.pricingAuditStatusesMap.get(
      row.PricingAuditStatusDto.PricingAuditStatusName
    );
    if (pricingAuditStatus) {
      row.PricingAuditStatusDto.PricingAuditStatusID =
        pricingAuditStatus.AuditStatusID;
    }
  }

  private updateStateData(row: BulkMobileTransactionDto): void {
    const state = this.stateMap.get(
      row.InvoiceDetailDto.CustomerLocation.State.StateName
    );
    if (state) {
      row.InvoiceDetailDto.CustomerLocation.State.StateID = state.StateID;
    }
  }

  private currencyFormatter(
    params: ValueFormatterParams,
    numberOfSigFig: number = 2
  ): string {
    return params.value !== null && params.value !== undefined ? `$${params.value.toFixed(numberOfSigFig)}` : '';
  }

  openSnackBar(message: string): void {
    this._snackBar.open(message, 'Close', {
      horizontalPosition: 'start',
      verticalPosition: 'top',
      duration: 2000,
    });
  }

  updateColumns() {
    // Note: If they make an update, and then change the group, the changes should be lost.
    this.colDefs = this.initializeColDefs();
    this.resetGrid();
  }

  onSubmit() {
    const queryParams = this.getSearchParams();
    this.gridService.getBulkMobileTransactions(queryParams).subscribe({
      next: bulkMobileTransactions => {
        this.assignRowData(bulkMobileTransactions);
      },
    });
  }

  private assignRowData(bulkMobileTransactions: BulkMobileTransactionDto[]) {
    bulkMobileTransactions.forEach(bulk => {
      if (bulk.InvoiceDetailDto.InvoiceDate) {
        bulk.InvoiceDetailDto.InvoiceDate = new Date(
          bulk.InvoiceDetailDto.InvoiceDate + 'Z'
        );
      }
      if (bulk.InvoiceDetailDto.TransactionDate) {
        bulk.InvoiceDetailDto.TransactionDate = new Date(
          bulk.InvoiceDetailDto.TransactionDate + 'Z'
        );
      }
      if (bulk.InvoiceDetailDto.InvoiceReceivedDate) {
        bulk.InvoiceDetailDto.InvoiceReceivedDate = new Date(
          bulk.InvoiceDetailDto.InvoiceReceivedDate + 'Z'
        );
      }
    });
    this.rowData = bulkMobileTransactions;
  }

  private getSearchParams(): HttpParams {
    let httpParams = new HttpParams()
      .set('InvoiceNumber', this.searchForm.value.invoiceNumber || '')
      .set('CustomerName', this.searchForm.value.customerName || '')
      .set('LocationName', this.searchForm.value.locationName || '')
      .set('Vendor', this.searchForm.value.vendor || '')
      .set(
        'PricingAgreementName',
        this.searchForm.value.pricingAgreementName || ''
      )
      .set('TaxAuditStatusId', this.selectedGroup === 'Tax' ? (this.searchForm.value.auditStatus || '') : '')
      .set('PricingAuditStatusId', this.selectedGroup !== 'Tax' ? (this.searchForm.value.auditStatus || '') : '')
      .set('StateId', this.searchForm.value.locationState || '')
      .set('ProductCategoryId', this.searchForm.value.productCategory || '');
      return httpParams;
  }
}
