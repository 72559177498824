import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InvoiceDetailDto } from '../interfaces/dto/invoice-detail.dto';
import { Customer } from '../interfaces/customer';
import { CustomerLocation } from '../interfaces/customer-location';
import { BulkMobileTransactionDto } from '../interfaces/dto/bulk-mobile-transaction.dto';
import { PricingAgreementFilterDto } from '../interfaces/dto/pricing-agreement-filter.dto';
import { NetworkDto } from '../interfaces/dto/network.dto';
import { ProductCategoryDto } from '../interfaces/dto/product-category.dto';
import { TaxAuditStatusDto } from '../interfaces/dto/tax-audit-status.dto';
import { PricingAuditStatusDto } from '../interfaces/dto/pricing-audit-status.dto';
import { StateDto } from '../interfaces/dto/state.dto';
import { AuditStatusDto } from '../interfaces/dto/audit-status.dto';
import { FuelingTypeDto } from '../interfaces/dto/fueling-type.dto';
import { BulkUpdateDto } from '../interfaces/dto/bulk-save.dto';

@Injectable({
  providedIn: 'root'
})
export class GridService {
  private readonly apiUrl = environment.apiUrl;
  constructor(private readonly http: HttpClient) { }

  getBulkMobileTransactions(searchParams: HttpParams): Observable<BulkMobileTransactionDto[]>{
    return this.http.get<BulkMobileTransactionDto[]>(`${this.apiUrl}/bulk-mobile-transactions`, {params: searchParams});
  }

  updateBulkMobileTransactions(updates: BulkMobileTransactionDto[]): Observable<void>{
    return this.http.put<void>(`${this.apiUrl}/bulk-mobile-transactions`, updates);
  }

  getCustomers(): Observable<Customer[]>{
    return this.http.get<Customer[]>(`${this.apiUrl}/bulk-mobile-transactions/customers`);
  }

  getCustomerLocations(): Observable<CustomerLocation[]>{
    return this.http.get<CustomerLocation[]>(`${this.apiUrl}/bulk-mobile-transactions/customer-locations`);
  }

  getPricingAgreementFilterDtos(): Observable<PricingAgreementFilterDto[]>{
    return this.http.get<PricingAgreementFilterDto[]>(`${this.apiUrl}/bulk-mobile-transactions/pricing-agreements`);
  }
  
  getNetworkMaster(): Observable<NetworkDto[]>{
    return this.http.get<NetworkDto[]>(`${this.apiUrl}/bulk-mobile-transactions/network-master`);
  }

  getProductCategory(): Observable<ProductCategoryDto[]>{
    return this.http.get<ProductCategoryDto[]>(`${this.apiUrl}/bulk-mobile-transactions/product-category`);
  }

  getAuditStatuses(): Observable<AuditStatusDto[]>{
    return this.http.get<AuditStatusDto[]>(`${this.apiUrl}/bulk-mobile-transactions/audit-statuses`);
  }

  getFuelingTypes(): Observable<FuelingTypeDto[]>{
    return this.http.get<FuelingTypeDto[]>(`${this.apiUrl}/bulk-mobile-transactions/fueling-types`);
  }

  getPricingAuditStatuses(): Observable<PricingAuditStatusDto[]>{
    return this.http.get<PricingAuditStatusDto[]>(`${this.apiUrl}/bulk-mobile-transactions/pricing-audit-statuses`);
  }

  getStates(): Observable<StateDto[]>{
    return this.http.get<StateDto[]>(`${this.apiUrl}/bulk-mobile-transactions/states`);
  }

  bulkUpdateBulkMobileTransactions(data: BulkUpdateDto): Observable<void>{
    return this.http.put<void>(`${this.apiUrl}/bulk-mobile-transactions/bulk-update`, data);
  }
}
